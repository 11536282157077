import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { fetchHomepageData } from '../../store/teams/homepage/actions';
import {
  isLoadingTeamHomepage,
  getTeamHomepageLoadingError,
  getTeamHomepagePageData,
  hasLoadedTeamHomepage,
} from '../../store/teams/homepage/selectors';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import TEAM_PROP_TYPES from '../../lib/prop-types/team';
import withTeam from '../../lib/with-team';
import TeamHomepage from '../../components/team/homepage';
import PageDataLoadError from '../../components/errors/page-data-load-error';
import SeoHead from '../../components/seo';

class TeamHomepagePage extends React.Component {
  static async getInitialProps({ reduxStore, club, isServer, team, req, res }) {
    const initialProps = {
      activeSubSection: 'homepage',
      pageType: 'team.homepage',
    };

    if (!isServer) {
      return initialProps;
    }

    const { error } = await reduxStore.dispatch(fetchHomepageData(club.id, team.id, req));

    if (error && error.errorStatusCode && res) {
      res.statusCode = error.errorStatusCode;
    }

    return initialProps;
  }

  componentDidMount() {
    this.loadIfRequired();
  }

  componentDidUpdate() {
    this.loadIfRequired();
  }

  loadIfRequired = () => {
    const {
      isLoading,
      previouslyLoaded,
      loadPageData,
      loadingError,
      club: { id: clubId },
      team: { id: teamId },
    } = this.props;

    if (!isLoading && !previouslyLoaded && !loadingError) {
      loadPageData(clubId, teamId);
    }
  };

  render() {
    const { club, loadingError, pageData, team, previouslyLoaded, isLoading } = this.props;

    if (loadingError) {
      return <PageDataLoadError clubId={club.id} {...loadingError} />;
    }

    const image = get(pageData, 'info.teamPhoto', team.image);
    const title = get(pageData, 'info.title', team.name);
    const welcomeContent = get(pageData, 'info.welcomeContent', null);
    const imageText = get(pageData, 'info.teamPhotoText', null);
    return (
      <>
        <SeoHead
          club={club}
          descriptionTranslationKey="teams:homepage_meta_description"
          route="team.homepage"
          routeParams={{ teamId: team.id }}
          title={`${club.name} ${team.name}`}
          translationValues={{ teamName: team.name }}
        />
        <TeamHomepage
          key={`team_homepage_${team.id}`}
          club={club}
          team={team}
          image={image}
          title={title}
          welcomeContent={welcomeContent}
          imageText={imageText}
          {...pageData}
          loading={!previouslyLoaded || isLoading}
        />
      </>
    );
  }
}

TeamHomepagePage.defaultProps = {
  loadingError: null,
  pageData: null,
};

TeamHomepagePage.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingError: PropTypes.shape({}),
  loadPageData: PropTypes.func.isRequired,
  previouslyLoaded: PropTypes.bool.isRequired,
  pageData: PropTypes.shape({}),
  team: TEAM_PROP_TYPES.isRequired,
};

const mapStateToProps = (state, { teamId }) => ({
  isLoading: isLoadingTeamHomepage(state, teamId),
  loadingError: getTeamHomepageLoadingError(state, teamId),
  previouslyLoaded: hasLoadedTeamHomepage(state, teamId),
  pageData: getTeamHomepagePageData(state, teamId),
});

const dispatchToProps = {
  loadPageData: fetchHomepageData,
};

export default withTeam(connect(mapStateToProps, dispatchToProps)(TeamHomepagePage));
