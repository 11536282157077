import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Grid, MaxWidth } from '@pitchero/react-ui';
import PlaceholderCard from '../card/placeholder';
import ActivityStreamMessage from './message';

const ActivityStream = ({
  grid,
  isLoading,
  messages,
  placeholderLimit,
  placement,
  style: customStyle,
}) => {
  const renderPlaceholders = () => {
    const placeholders = [];
    if (isLoading) {
      const placeholderCount = placeholderLimit - messages.length;
      for (let i = 0; i < placeholderCount; i += 1) {
        placeholders.push(<PlaceholderCard key={i} stacked="tab-and-up" />);
      }
    }
    return placeholders;
  };

  return (
    <MaxWidth component="div" maxWidth={1366} center>
      <Grid preset={grid} style={customStyle}>
        {map(messages, (message) => (
          <ActivityStreamMessage
            key={message.id}
            message={message}
            placement={placement}
            stacked="tab-and-up"
          />
        ))}
        {renderPlaceholders()}
      </Grid>
    </MaxWidth>
  );
};

ActivityStream.defaultProps = {
  grid: 'activityStream',
  isLoading: false,
  placeholderLimit: 10,
  placement: null,
  style: {},
};

ActivityStream.propTypes = {
  grid: PropTypes.string,
  isLoading: PropTypes.bool,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  placeholderLimit: PropTypes.number,
  placement: PropTypes.string,
  style: PropTypes.shape(),
};

export default ActivityStream;
