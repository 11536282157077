import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Typography } from '@pitchero/react-ui';
import { Trans } from '../../../lib/i18n';

const MobileSubHeader = ({ title, transKey }) => (
  <Cushion bottom="medium" component="div">
    <Typography preset="subNavContentHeading" color="black">
      <Trans i18nKey={transKey}>{title}</Trans>
    </Typography>
  </Cushion>
);

MobileSubHeader.propTypes = {
  title: PropTypes.string.isRequired,
  transKey: PropTypes.string.isRequired,
};

export default MobileSubHeader;
