import { Cushion, Grid, MaxWidth, Rectangle } from '@pitchero/react-ui';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import RosBottomBanner from '../../adverts/ros-bottom-banner';
import ContactOfficialPanel from '../../contact/contact-official-panel';
import OfficialGroup from '../../contact/official-group';
import NativeShelf from '../../native-adverts/shelf';
import { recaptchaV3Key } from '../../../lib/env';
import { ACTIVITY_STREAM_MESSAGES_PROP_TYPE } from '../../../lib/prop-types/activity-stream-message';
import CLUB_PROP_TYPES from '../../../lib/prop-types/club';
import { FIXTURE_PROP_TYPE } from '../../../lib/prop-types/fixture';
import TABLE_PROP_TYPES from '../../../lib/prop-types/table';
import TEAM_PROP_TYPES from '../../../lib/prop-types/team';
import TeamHomepageInfo from './info';
import TeamHomepageLoadingState from './loading-state';
import TeamHomepageMatches from './matches';
import TeamHomepageMedia from './media';
import TeamHomepageNews from './news';
import TeamHomepageTables from './tables';
import TeamHomepageTraining from './training';

const TeamHomepage = ({
  club,
  image,
  imageText,
  loading,
  matches,
  media,
  news,
  officials,
  tables,
  team,
  title,
  trainingSessions,
  welcomeContent,
}) => {
  if (loading) {
    return <TeamHomepageLoadingState />;
  }

  const hasTableOrTraining = !isEmpty(tables) || !isEmpty(trainingSessions);
  const hasTableAndTraining = !isEmpty(tables) && !isEmpty(trainingSessions);
  const hasNewsOrMedia = !isEmpty(news) || !isEmpty(media);
  const hasNewsAndMedia = !isEmpty(news) && !isEmpty(media);
  const hasContactableOfficial = officials.filter((official) => official.canEmail).length > 0;

  const [officialInPanel, setOfficialInPanel] = useState(null);

  return (
    <>
      <div>
        <TeamHomepageInfo
          club={club}
          image={image}
          team={team}
          imageText={imageText}
          title={title}
          welcomeContent={welcomeContent}
        />
        {!isEmpty(matches) && <TeamHomepageMatches matches={matches} />}
        {hasTableOrTraining && (
          <Rectangle fill="seashellgrey">
            <Cushion
              vertical="large"
              horizontal="small"
              responsive={[
                { minWidth: 'tab', props: { vertical: 'xlarge', horizontal: 'medium' } },
              ]}
              component="div"
            >
              <MaxWidth component="div" maxWidth={1366} center>
                <Grid
                  className={hasTableAndTraining ? 'ie-grid-two-col' : ''}
                  columns={`repeat(${hasTableAndTraining ? 2 : 1}, minmax(0, 1fr))`}
                  responsive={[{ maxWidth: 'tab', props: { columns: '1fr' } }]}
                  columnGap="xlarge"
                  rowGap="xlarge"
                >
                  {!isEmpty(tables) && <TeamHomepageTables tables={tables} teamId={team.id} />}
                  {!isEmpty(trainingSessions) && (
                    <TeamHomepageTraining
                      club={club}
                      trainingSessions={trainingSessions}
                      teamId={team.id}
                    />
                  )}
                </Grid>
              </MaxWidth>
            </Cushion>
          </Rectangle>
        )}

        <MaxWidth component="div" maxWidth={1366} center>
          <RosBottomBanner />
        </MaxWidth>

        {!isEmpty(officials) && (
          <Rectangle fill="seashellgrey">
            <Cushion
              vertical="large"
              horizontal="small"
              responsive={[
                { minWidth: 'tab', props: { vertical: 'xlarge', horizontal: 'medium' } },
              ]}
              component="div"
            >
              <MaxWidth component="div" maxWidth={1366} center>
                <OfficialGroup
                  officialGroup={{ name: 'Officials', officials }}
                  onClickEmail={(official) => setOfficialInPanel(official)}
                />
              </MaxWidth>
            </Cushion>
          </Rectangle>
        )}

        {hasNewsOrMedia && (
          <Rectangle fill="seashellgrey">
            <Cushion
              vertical="large"
              horizontal="small"
              responsive={[
                { minWidth: 'tab', props: { vertical: 'xlarge', horizontal: 'medium' } },
              ]}
              component="div"
            >
              <MaxWidth component="div" maxWidth={1366} center>
                <Grid
                  className={hasNewsAndMedia ? 'ie-grid-two-col' : ''}
                  columns={`repeat(${hasNewsAndMedia ? 2 : 1}, minmax(0, 1fr))`}
                  responsive={[{ maxWidth: 'tab', props: { columns: '1fr' } }]}
                  columnGap="xlarge"
                  rowGap="xlarge"
                >
                  {!isEmpty(news) && (
                    <TeamHomepageNews
                      news={news.slice(0, hasNewsAndMedia ? 2 : 4)}
                      teamId={team.id}
                      fullWidth={!hasNewsAndMedia}
                    />
                  )}
                  {!isEmpty(media) && (
                    <TeamHomepageMedia
                      media={media.slice(0, hasNewsAndMedia ? 2 : 4)}
                      teamId={team.id}
                      fullWidth={!hasNewsAndMedia}
                    />
                  )}
                </Grid>
              </MaxWidth>
            </Cushion>
          </Rectangle>
        )}
        <NativeShelf paddingProps={{ bottom: 'medium' }} />
      </div>
      {hasContactableOfficial && (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaV3Key()}>
          <ContactOfficialPanel
            isOpen={!!officialInPanel}
            club={club}
            official={officialInPanel}
            onClose={() => setOfficialInPanel(null)}
          />
        </GoogleReCaptchaProvider>
      )}
    </>
  );
};

TeamHomepage.defaultProps = {
  imageText: null,
  loading: false,
  matches: [],
  media: [],
  news: [],
  officials: [],
  tables: [],
  trainingSessions: [],
  welcomeContent: null,
};

TeamHomepage.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  image: PropTypes.string.isRequired,
  imageText: PropTypes.string,
  loading: PropTypes.bool,
  matches: PropTypes.arrayOf(FIXTURE_PROP_TYPE),
  media: ACTIVITY_STREAM_MESSAGES_PROP_TYPE,
  news: ACTIVITY_STREAM_MESSAGES_PROP_TYPE,
  officials: PropTypes.arrayOf(PropTypes.shape()),
  tables: PropTypes.arrayOf(TABLE_PROP_TYPES),
  team: TEAM_PROP_TYPES.isRequired,
  title: PropTypes.string.isRequired,
  trainingSessions: PropTypes.arrayOf(PropTypes.shape()),
  welcomeContent: PropTypes.string,
};

export default TeamHomepage;
