import React from 'react';
import {
  Cushion,
  Grid,
  MaxWidth,
  Shadow,
  ShimmerImage,
  ShimmerTypography,
  Space,
} from '@pitchero/react-ui';
import NoBackground from '../../club-frame/header/no-background';
import PlaceholderCard from '../../card/placeholder';
import FixtureOverviewPlaceHolder from '../../fixture/overview-placeholder';

const TeamHomepageLoadingState = () => (
  <MaxWidth component="div" maxWidth={1366} center>
    <NoBackground />
    <Cushion top="xlarge" bottom="xxxlarge" horizontal="large" component="div">
      <Grid
        areas="'info image'"
        columns="1fr 1fr"
        columnGap="large"
        responsive={[
          {
            maxWidth: 'tab',
            props: {
              columns: 'auto',
              areas: "'image' 'info'",
            },
          },
        ]}
        rowGap="large"
      >
        <div style={{ gridArea: 'info' }}>
          <Space bottom="small">
            <ShimmerTypography preset="tab--active" width="30%" />
          </Space>
          <Space bottom="medium">
            <ShimmerTypography preset="title" />
          </Space>
          <ShimmerTypography preset="body--large" />
          <ShimmerTypography preset="body--large" />
          <ShimmerTypography preset="body--large" />
          <ShimmerTypography preset="body--large" width="40%" />
        </div>
        <div style={{ gridArea: 'image' }}>
          <Shadow>
            <ShimmerImage preset="card--large" fullWidth />
          </Shadow>
        </div>
      </Grid>
    </Cushion>

    <Space bottom="large">
      <Cushion vertical="medium" horizontal="medium" component="div">
        <Space bottom="small">
          <ShimmerTypography preset="tab--active" width={100} />
        </Space>
        <Space bottom="medium">
          <ShimmerTypography preset="title" width="40%" />
        </Space>

        <Grid
          columns="minmax(0, 1fr)"
          responsive={[{ minWidth: 'lap', props: { columns: 'repeat(3, minmax(0, 1fr))' } }]}
          rowGap="medium"
          columnGap="medium"
        >
          <FixtureOverviewPlaceHolder />
          <FixtureOverviewPlaceHolder />
          <FixtureOverviewPlaceHolder />
        </Grid>
      </Cushion>
    </Space>

    <Cushion vertical="medium" horizontal="medium" component="div">
      <Space bottom="small">
        <ShimmerTypography preset="tab--active" width={100} />
      </Space>
      <Space bottom="medium">
        <ShimmerTypography preset="title" width="40%" />
      </Space>
    </Cushion>

    <Cushion vertical="medium" horizontal="medium" component="div">
      <Grid preset="activityStream">
        <PlaceholderCard />
        <PlaceholderCard />
        <PlaceholderCard />
        <PlaceholderCard />
      </Grid>
    </Cushion>
  </MaxWidth>
);

export default TeamHomepageLoadingState;
