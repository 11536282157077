import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Rectangle, ShimmerImage, ShimmerTypography } from '@pitchero/react-ui';
import MediaItem from '../media-item';

const PlaceholderCard = ({ stacked }) => (
  <Rectangle radius="rounded">
    <MediaItem
      stacked={stacked}
      renderImage={() => (
        <ShimmerImage style={{ borderRadius: '4px 4px 0 0' }} preset="card" fullWidth />
      )}
      renderBody={() => (
        <>
          <Cushion bottom="small" component="div">
            <ShimmerTypography preset="tag--large" width="20%" />
          </Cushion>
          <ShimmerTypography preset="subtitle--large" width="100%" />
          <ShimmerTypography preset="subtitle--large" width="75%" />
          <Cushion top="medium" component="div">
            <ShimmerTypography preset="body--small" width="30%" />
          </Cushion>
        </>
      )}
    />
  </Rectangle>
);

PlaceholderCard.defaultProps = {
  stacked: 'always',
};

PlaceholderCard.propTypes = {
  stacked: PropTypes.oneOf(['always', 'tab-and-up', 'desk', 'never']),
};

export default PlaceholderCard;
