import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@pitchero/react-ui';
import TABLE_PROP_TYPES from '../../../lib/prop-types/table';

const TableSelect = ({ onSelectTable, selectedTableId, tables }) => {
  const options = tables.map((table) => ({
    value: table.id,
    name: table.name,
  }));

  return (
    <Select
      input={{ value: selectedTableId }}
      options={options}
      onChange={(event) => onSelectTable(parseInt(event.target.value, 10))}
    />
  );
};

TableSelect.propTypes = {
  onSelectTable: PropTypes.func.isRequired,
  selectedTableId: PropTypes.number.isRequired,
  tables: PropTypes.arrayOf(TABLE_PROP_TYPES).isRequired,
};

export default TableSelect;
