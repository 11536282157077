import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Grid, MaxWidth, Rectangle, Shadow, Space, Typography } from '@pitchero/react-ui';
import { FIXTURE_PROP_TYPE } from '../../../lib/prop-types/fixture';
import { Trans } from '../../../lib/i18n';
import FixtureOverview from '../../fixture/overview';

const TeamHomepageMatches = ({ matches }) => (
  <Rectangle fill="seashellgrey">
    <Cushion
      vertical="large"
      horizontal="small"
      responsive={[{ minWidth: 'tab', props: { vertical: 'xlarge', horizontal: 'medium' } }]}
      component="div"
    >
      <MaxWidth component="div" maxWidth={1366} center>
        <Space bottom="small">
          <Typography
            preset="tab--active"
            responsive={[{ maxWidth: 'tab', props: { preset: 'tag--large' } }]}
            color="primary"
            component="h3"
          >
            <Trans i18nKey="teams:homepage_matches_header">Matches</Trans>
          </Typography>
        </Space>
        <Space bottom="medium">
          <Typography preset="title" color="black" component="h4">
            <Trans i18nKey="teams:homepage_matches_subheader">Recent and upcoming</Trans>
          </Typography>
        </Space>
        <Grid
          columns="minmax(0, 1fr)"
          responsive={[
            {
              minWidth: 'lap',
              props: { columns: `repeat(${matches.length}, minmax(0, 1fr))`, rowGap: 'medium' },
            },
          ]}
          rowGap="small"
          columnGap="medium"
        >
          {matches.map((fixture) => (
            <Shadow key={fixture.id}>
              <Rectangle fill="white" radius="rounded" component="div">
                <FixtureOverview fixture={fixture} useInternalTeamName />
              </Rectangle>
            </Shadow>
          ))}
        </Grid>
      </MaxWidth>
    </Cushion>
  </Rectangle>
);

TeamHomepageMatches.propTypes = {
  matches: PropTypes.arrayOf(FIXTURE_PROP_TYPE).isRequired,
};
export default TeamHomepageMatches;
